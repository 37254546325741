@font-face {
    font-family: 'Market Fresh Inline Bold All Caps';
    src: url('MarketFreshInlineBoldAllCaps.woff2') format('woff2'),
        url('MarketFreshInlineBoldAllCaps.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh';
    src: url('MarketFreshBold.woff2') format('woff2'),
        url('MarketFreshBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh Bold All Caps';
    src: url('MarketFreshBoldAllCaps.woff2') format('woff2'),
        url('MarketFreshBoldAllCaps.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh Bold All Caps Ital';
    src: url('MarketFreshBoldAllCapsItal-Rg.woff2') format('woff2'),
        url('MarketFreshBoldAllCapsItal-Rg.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh All Caps';
    src: url('MarketFreshAllCapsItalic-Reg.woff2') format('woff2'),
        url('MarketFreshAllCapsItalic-Reg.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh';
    src: url('MarketFreshBoldItalic-Regular.woff2') format('woff2'),
        url('MarketFreshBoldItalic-Regular.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh All Caps';
    src: url('MarketFreshAllCaps.woff2') format('woff2'),
        url('MarketFreshAllCaps.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh Inline Bold All Ca';
    src: url('MarketFreshInlineBoldAllCa.woff2') format('woff2'),
        url('MarketFreshInlineBoldAllCa.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh';
    src: url('MarketFreshItalic-Regular.woff2') format('woff2'),
        url('MarketFreshItalic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Market Fresh';
    src: url('MarketFresh.woff2') format('woff2'),
        url('MarketFresh.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

